import React, { useState, useEffect } from "react";
import axiosInstance from "../api/api";
import { FiSave, FiLock, FiEye, FiEyeOff } from "react-icons/fi";
import ErrorMessage from "../components/ErrorMessage";

const Settings = () => {
  const [companyInfo, setCompanyInfo] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    working_days: [],
    start_time: "",
    end_time: "",
    time_range: 30,
    extra_info: "",
  });
  const [message, setMessage] = useState({ text: "", type: "error" });
  const [passwordInfo, setPasswordInfo] = useState({
    old_password: "",
    new_password: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  useEffect(() => {
    fetchCompanyInfo();
  }, []);

  const fetchCompanyInfo = async () => {
    try {
      const response = await axiosInstance.get("/api/company/");
      setCompanyInfo(response.data[0]);
    } catch (error) {
      setMessage({
        text:
          "Şirket bilgisi çekme hatası: " + error.response?.data?.message ||
          error.message,
        type: "error",
      });
    }
  };

  const handleInputChange = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };

  const handleWorkingDaysChange = (day) => {
    setCompanyInfo((prevState) => ({
      ...prevState,
      working_days: prevState.working_days.includes(day)
        ? prevState.working_days.filter((d) => d !== day)
        : [...prevState.working_days, day],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.patch(`/api/company/${companyInfo.id}/`, companyInfo);
      setMessage({ text: "Bilgiler başarıyla güncellendi.", type: "success" });
    } catch (error) {
      setMessage({
        text:
          "Şirket bilgisi güncelleme hatası: " +
            error.response?.data?.message || error.message,
        type: "error",
      });
    }
  };

  const handlePasswordChange = (e) => {
    setPasswordInfo({ ...passwordInfo, [e.target.name]: e.target.value });
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put("/api/update-password/", passwordInfo);
      setMessage({ text: "Parola başarıyla güncellendi.", type: "success" });
      setPasswordInfo({ old_password: "", new_password: "" });
    } catch (error) {
      setMessage({
        text:
          "Şifre güncelleme hatası: " + error.response?.data?.message ||
          error.message,
        type: "error",
      });
    }
  };

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-800 dark:to-gray-900 min-h-screen p-4 sm:p-6 md:p-8 text-gray-800 dark:text-gray-200 font-sans rounded-xl">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 sm:mb-8 md:mb-10 text-blue-600 dark:text-blue-400">
          Şirket Ayarları
        </h2>

        <form
          onSubmit={handleSubmit}
          className="space-y-6 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md mb-8"
        >
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
            >
              Şirket Adı
            </label>
            <input
              id="name"
              type="text"
              name="name"
              value={companyInfo.name}
              onChange={handleInputChange}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
            >
              E-posta
            </label>
            <input
              id="email"
              type="email"
              name="email"
              value={companyInfo.email}
              onChange={handleInputChange}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
            />
          </div>
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
            >
              Telefon
            </label>
            <input
              id="phone"
              type="tel"
              name="phone"
              value={companyInfo.phone}
              onChange={handleInputChange}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
            />
          </div>
          <div>
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
            >
              Adres
            </label>
            <textarea
              id="address"
              name="address"
              value={companyInfo.address}
              onChange={handleInputChange}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
              rows="3"
            ></textarea>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Çalışma Günleri
            </label>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
              {[
                "Pazartesi",
                "Salı",
                "Çarşamba",
                "Perşembe",
                "Cuma",
                "Cumartesi",
                "Pazar",
              ].map((day, index) => (
                <label key={index} className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={companyInfo.working_days.includes(index + 1)}
                    onChange={() => handleWorkingDaysChange(index + 1)}
                    className="form-checkbox h-5 w-5 text-blue-600 dark:text-blue-400 rounded focus:ring-blue-400 dark:focus:ring-blue-600 transition duration-150 ease-in-out"
                  />
                  <span className="ml-2 text-gray-700 dark:text-gray-300">
                    {day}
                  </span>
                </label>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="start_time"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
              >
                Başlangıç Saati
              </label>
              <input
                id="start_time"
                type="time"
                name="start_time"
                value={companyInfo.start_time}
                onChange={handleInputChange}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
              />
            </div>
            <div>
              <label
                htmlFor="end_time"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
              >
                Bitiş Saati
              </label>
              <input
                id="end_time"
                type="time"
                name="end_time"
                value={companyInfo.end_time}
                onChange={handleInputChange}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="time_range"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
            >
              Randevu Aralığı (dakika)
            </label>
            <input
              id="time_range"
              type="number"
              name="time_range"
              value={companyInfo.time_range}
              onChange={handleInputChange}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
            />
          </div>
          <div>
            <label
              htmlFor="extra_info"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
            >
              Ek Bilgi
            </label>
            <textarea
              id="extra_info"
              name="extra_info"
              value={companyInfo.extra_info}
              onChange={handleInputChange}
              className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
              rows="3"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800 transition duration-150 ease-in-out flex items-center justify-center"
          >
            <FiSave className="mr-2" />
            Güncelle
          </button>
        </form>

        <form
          onSubmit={handlePasswordSubmit}
          className="space-y-6 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
        >
          <h3 className="text-xl font-semibold mb-4 text-blue-600 dark:text-blue-400">
            Parola Değiştir
          </h3>
          <div className="relative">
            <label
              htmlFor="old_password"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
            >
              Mevcut Parola
            </label>
            <div className="relative">
              <input
                id="old_password"
                type={showOldPassword ? "text" : "password"}
                name="old_password"
                value={passwordInfo.old_password}
                onChange={handlePasswordChange}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out pr-10"
              />
              <button
                type="button"
                onClick={() => setShowOldPassword(!showOldPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              >
                {showOldPassword ? (
                  <FiEyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <FiEye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
          </div>
          <div className="relative">
            <label
              htmlFor="new_password"
              className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
            >
              Yeni Parola
            </label>
            <div className="relative">
              <input
                id="new_password"
                type={showNewPassword ? "text" : "password"}
                name="new_password"
                value={passwordInfo.new_password}
                onChange={handlePasswordChange}
                className="w-full px-3 py-2 bg-white dark:bg-gray-700 rounded-md text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 focus:border-blue-300 dark:focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-600 focus:ring-opacity-50 transition duration-150 ease-in-out pr-10"
              />
              <button
                type="button"
                onClick={() => setShowNewPassword(!showNewPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              >
                {showNewPassword ? (
                  <FiEyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <FiEye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:ring-offset-2 dark:focus:ring-offset-gray-800 transition duration-150 ease-in-out flex items-center justify-center"
          >
            <FiLock className="mr-2" />
            Parolayı Güncelle
          </button>
        </form>

        <ErrorMessage
          message={message.text}
          type={message.type}
          onClose={() => setMessage({ text: "", type: "error" })}
        />
      </div>
    </div>
  );
};

export default Settings;
