import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../api/api";
import {
  FiEdit2,
  FiTrash2,
  FiPlus,
  FiSearch,
  FiInfo,
  FiLoader,
  FiChevronLeft,
  FiChevronRight,
  FiClock,
} from "react-icons/fi";
import { AiTwotoneShopping } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import CustomerDetailModal from "../components/customers/CustomerDetailModal";
import SaleModal from "../components/customers/SaleModal";
import CustomerModal from "../components/customers/CustomerModal";
import ErrorMessage from "../components/ErrorMessage";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [packages, setPackages] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isSaleModalOpen, setIsSaleModalOpen] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const [paymentInfo, setPaymentInfo] = useState({
    amount: "",
    payed_amount: "",
    discount: "0",
    payment_type: "cash",
  });
  const [saleInfo, setSaleInfo] = useState({
    customer: null,
    products: [],
  });
  const [filterText, setFilterText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [packageSearchTerm, setPackageSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCustomerForDetail, setSelectedCustomerForDetail] =
    useState(null);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const [customersResponse, packagesResponse, productsResponse] =
        await Promise.all([
          axiosInstance.get(
            `/api/customer/?ordering=-created_at&limit=${limit}&page=${currentPage}&search=${filterText}`
          ),
          axiosInstance.get("/api/company-package/"),
          axiosInstance.get("/api/product/"),
        ]);
      setCustomers(customersResponse.data.results);
      setTotalPages(Math.ceil(customersResponse.data.count / limit));
      setPackages(packagesResponse.data);
      setProducts(productsResponse.data);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Veri çekme hatası");
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, limit, filterText]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = useCallback((customer) => {
    setSelectedCustomer(customer);
    setPaymentInfo({
      amount: customer.payments[0]?.amount || "",
      payed_amount: customer.payments[0]?.payed_amount || "",
      discount: customer.payments[0]?.discount || "0",
      payment_type: customer.payments[0]?.payment_type || "cash",
      description: customer.payments[0]?.description || "",
    });
    setIsModalOpen(true);
  }, []);

  const handleDeleteClick = useCallback(async (customer) => {
    try {
      const response = await axiosInstance.get(`/api/customer/${customer.id}/`);
      const totalDebt = response.data.payments.reduce(
        (acc, payment) =>
          acc + (parseFloat(payment.amount) - parseFloat(payment.payed_amount)),
        0
      );
      setCustomerToDelete({ ...customer, totalDebt });
      setIsDeleteModalOpen(true);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "Müşteri borç bilgisi çekme hatası"
      );
    }
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      await axiosInstance.patch(`/api/customer/${customerToDelete.id}/`, {
        is_active: false,
      });
      fetchData();
      setIsDeleteModalOpen(false);
      setSuccessMessage("Müşteri başarıyla silindi");
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Müşteri silme hatası");
    }
  }, [customerToDelete, fetchData]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const updatedPaymentInfo = {
          amount: parseFloat(paymentInfo.amount) || 0,
          payed_amount: parseFloat(paymentInfo.payed_amount) || 0,
          discount: parseFloat(paymentInfo.discount) || 0,
          payment_type: paymentInfo.payment_type,
          description: paymentInfo.description,
        };

        if (selectedCustomer.id) {
          // mevcut müşteri güncelleme
          await axiosInstance.patch(
            `/api/customer/${selectedCustomer.id}/`,
            selectedCustomer
          );

          if (
            updatedPaymentInfo.amount > 0 ||
            updatedPaymentInfo.payed_amount > 0
          ) {
            if (
              selectedCustomer.payments &&
              selectedCustomer.payments.length > 0
            ) {
              // mevcut ödeme güncelleme
              await axiosInstance.patch(
                `/api/payment/${selectedCustomer.payments[0].id}/`,
                updatedPaymentInfo
              );
            } else {
              // yeni ödeme oluşturma (mevcut müşteri için)
              await axiosInstance.post("/api/payment/", {
                ...updatedPaymentInfo,
                customer: selectedCustomer.id,
              });
            }
          }
        } else {
          // yeni müşteri oluşturma
          const newCustomerData = {
            ...selectedCustomer,
            payments: [],
          };

          if (
            updatedPaymentInfo.amount > 0 ||
            updatedPaymentInfo.payed_amount > 0
          ) {
            newCustomerData.payments.push(updatedPaymentInfo);
          }

          const response = await axiosInstance.post(
            "/api/customer/",
            newCustomerData
          );

          if (newCustomerData.payments.length > 0 && response.data.id) {
            // yeni müşteri için ödeme oluşturma
            await axiosInstance.post("/api/payment/", {
              ...updatedPaymentInfo,
              customer: response.data.id,
            });
          }
        }

        setIsModalOpen(false);
        setSelectedCustomer(null);
        setPaymentInfo({
          amount: "",
          payed_amount: "",
          discount: "0",
          payment_type: "cash",
          description: "",
        });

        fetchData();
      } catch (error) {
        setErrorMessage(
          error.response?.data?.message ||
            "Müşteri kaydetme hatası: " + error.message
        );
      }
    },
    [selectedCustomer, paymentInfo, fetchData]
  );

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const phoneNumber = value.startsWith("+90")
        ? value
        : "+90" + value.replace(/^0/, "");
      setSelectedCustomer((prev) => ({ ...prev, [name]: phoneNumber }));
    } else {
      setSelectedCustomer((prev) => ({ ...prev, [name]: value }));
    }
  }, []);

  const handlePackageChange = useCallback(
    (e) => {
      const packageId = parseInt(e.target.value);
      const packageInfo = packages.find((pkg) => pkg.id === packageId);
      const isChecked = e.target.checked;

      setSelectedCustomer((prev) => {
        const updatedPackages = isChecked
          ? [...(prev.company_packages || []), packageId]
          : (prev.company_packages || []).filter((id) => id !== packageId);

        const updatedPayments = [...(prev.payments || [])];
        if (updatedPayments.length === 0) {
          updatedPayments.push({ amount: 0, description: "", payed_amount: 0 });
        }

        const currentAmount = parseFloat(updatedPayments[0].amount) || 0;
        const packagePrice = parseFloat(packageInfo.price) || 0;

        updatedPayments[0] = {
          ...updatedPayments[0],
          amount: isChecked
            ? currentAmount + packagePrice
            : currentAmount - packagePrice,
          description: isChecked
            ? `${updatedPayments[0].description} ${packageInfo.name} -${packageInfo.price}`
            : updatedPayments[0].description.replace(
                ` ${packageInfo.name} -${packageInfo.price}`,
                ""
              ),
        };

        return {
          ...prev,
          company_packages: updatedPackages,
          payments: updatedPayments,
        };
      });
    },
    [packages]
  );

  const handleProfilePaymentInputChange = useCallback((event, field) => {
    const value = event.target.value;
    setSelectedCustomer((prevState) => {
      const payments =
        prevState.payments && prevState.payments.length > 0
          ? prevState.payments
          : [{}];

      return {
        ...prevState,
        payments: [
          {
            ...payments[0],
            [field]: value,
          },
        ],
      };
    });
  }, []);

  const handlePaymentInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setPaymentInfo((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleDetailClick = useCallback(async (customer) => {
    try {
      const response = await axiosInstance.get(`/api/customer/${customer.id}/`);
      setSelectedCustomerForDetail(response.data);
      setIsDetailModalOpen(true);
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "Müşteri detay bilgisi çekme hatası"
      );
    }
  }, []);

  const handleSaleClick = useCallback((customer) => {
    setSaleInfo({ customer: customer, products: [] });
    setIsSaleModalOpen(true);
  }, []);

  const handleProductChange = useCallback((e) => {
    const productId = parseInt(e.target.value);
    setSaleInfo((prev) => ({
      ...prev,
      products: e.target.checked
        ? [...prev.products, productId]
        : prev.products.filter((id) => id !== productId),
    }));
  }, []);

  const handleSaleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        await axiosInstance.patch(`/api/customer/${saleInfo.customer.id}/`, {
          customer: saleInfo.customer.id,
          products: saleInfo.products,
        });
        setIsSaleModalOpen(false);
        fetchData();
      } catch (error) {
        setErrorMessage(error.response?.data?.message || "Satış kaydı hatası");
      }
    },
    [saleInfo, fetchData]
  );

  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
  }, []);

  const handleLimitChange = useCallback((e) => {
    setLimit(Number(e.target.value));
    setCurrentPage(1);
  }, []);

  const handleFilterChange = useCallback((e) => {
    setFilterText(e.target.value);
    setCurrentPage(1);
  }, []);

  const handleDetailUpdate = useCallback((id, newDescription) => {
    setSelectedCustomerForDetail((prevCustomer) => {
      if (!prevCustomer) return prevCustomer;

      return {
        ...prevCustomer,
        payment_history: prevCustomer.payment_history.map((payment) =>
          payment.id === id
            ? { ...payment, description: newDescription }
            : payment
        ),
      };
    });
  }, []);

  return (
    <div className="bg-gradient-to-br from-blue-50 to-white dark:from-gray-800 dark:to-gray-900 min-h-screen p-4 sm:p-6 md:p-8 text-gray-800 dark:text-gray-200 font-sans rounded-xl">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 md:mb-0 text-blue-600 dark:text-blue-400">
            Müşteriler
          </h2>
          <div className="flex flex-col sm:flex-row w-full md:w-auto space-y-2 sm:space-y-0 sm:space-x-2">
            <div className="relative flex-grow">
              <input
                type="text"
                placeholder="Müşteri Ara..."
                value={filterText}
                onChange={handleFilterChange}
                className="w-full px-4 py-2 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded-md border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-500"
              />
              <FiSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" />
            </div>
            <button
              onClick={() => {
                setSelectedCustomer({});
                setIsModalOpen(true);
                setPaymentInfo({
                  amount: "",
                  payed_amount: "",
                  discount: "0",
                  payment_type: "cash",
                  description: "",
                });
              }}
              className="bg-blue-500 dark:bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 transition duration-300 flex items-center justify-center"
            >
              <FiPlus className="mr-2" /> Yeni Müşteri
            </button>
            <Link
              to="/overduepayments"
              className="bg-yellow-500 dark:bg-yellow-600 text-white px-4 py-2 rounded-md hover:bg-yellow-600 dark:hover:bg-yellow-700 transition duration-300 flex items-center justify-center"
            >
              <FiClock className="mr-2" /> Borcu Olanlar
            </Link>
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <FiLoader className="animate-spin text-blue-500 text-4xl" />
            <span className="ml-2 text-lg">Müşteriler Yükleniyor...</span>
          </div>
        ) : (
          <>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
              <div className="overflow-x-auto">
                <div className="max-h-[500px] overflow-y-auto">
                  <table className="w-full min-w-max table-auto">
                    <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0 z-10">
                      <tr>
                        {[
                          "İsim",
                          "Telefon",
                          "Notlar",
                          "Paketler",
                          "İşlemler",
                        ].map((header, index) => (
                          <th
                            key={index}
                            className={`px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider ${
                              header === "Paketler" ? "w-64" : ""
                            }`}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                      {customers.map((customer) => (
                        <tr
                          key={customer.id}
                          className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
                        >
                          <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                            {customer.name}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-gray-800 dark:text-gray-200">
                            {customer.phone}
                          </td>
                          <td className="px-4 py-3 max-w-xs text-gray-800 dark:text-gray-200 overflow-hidden text-ellipsis whitespace-nowrap">
                            {customer.note}
                          </td>
                          <td className="px-4 py-3 w-64">
                            <div className="flex flex-wrap">
                              {customer.company_packages_info.map((pkg) => (
                                <span
                                  key={pkg.id}
                                  className="inline-block bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 px-2 py-1 rounded-full text-xs mr-1 mb-1"
                                >
                                  {pkg.name}
                                </span>
                              ))}
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm font-medium">
                            <div className="flex space-x-2">
                              <button
                                onClick={() => handleDetailClick(customer)}
                                className="bg-blue-100 dark:bg-blue-800 text-blue-600 dark:text-blue-200 hover:bg-blue-200 dark:hover:bg-blue-700 rounded-full p-2 transition duration-300"
                              >
                                <FiInfo />
                              </button>
                              <button
                                onClick={() => handleEdit(customer)}
                                className="bg-green-100 dark:bg-green-800 text-green-600 dark:text-green-200 hover:bg-green-200 dark:hover:bg-green-700 rounded-full p-2 transition duration-300"
                              >
                                <FiEdit2 />
                              </button>
                              <button
                                onClick={() => handleDeleteClick(customer)}
                                className="bg-red-100 dark:bg-red-800 text-red-600 dark:text-red-200 hover:bg-red-200 dark:hover:bg-red-700 rounded-full p-2 transition duration-300"
                              >
                                <FiTrash2 />
                              </button>
                              <button
                                onClick={() => handleSaleClick(customer)}
                                className="bg-purple-100 dark:bg-purple-800 text-purple-600 dark:text-purple-200 hover:bg-purple-200 dark:hover:bg-purple-700 rounded-full p-2 transition duration-300"
                              >
                                <AiTwotoneShopping />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="mt-4 flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <span>Sayfa başına:</span>
                <select
                  value={limit}
                  onChange={handleLimitChange}
                  className="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-200 rounded-md px-2 py-1"
                >
                  {[50, 100, 150, 200].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-3 py-1 bg-blue-500 text-white rounded-md disabled:bg-gray-300 disabled:text-gray-500"
                >
                  <FiChevronLeft />
                </button>
                <span>
                  {currentPage} / {totalPages}
                </span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="px-3 py-1 bg-blue-500 text-white rounded-md disabled:bg-gray-300 disabled:text-gray-500"
                >
                  <FiChevronRight />
                </button>
              </div>
            </div>
          </>
        )}

        <CustomerModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedCustomer={selectedCustomer}
          packages={packages}
          packageSearchTerm={packageSearchTerm}
          setPackageSearchTerm={setPackageSearchTerm}
          handleInputChange={handleInputChange}
          handlePackageChange={handlePackageChange}
          handleProfilePaymentInputChange={handleProfilePaymentInputChange}
          handleSubmit={handleSubmit}
          paymentInfo={paymentInfo}
          handlePaymentInputChange={handlePaymentInputChange}
        />

        <CustomerDetailModal
          customer={selectedCustomerForDetail}
          isOpen={isDetailModalOpen}
          onClose={() => setIsDetailModalOpen(false)}
          onUpdate={handleDetailUpdate}
        />

        <SaleModal
          isOpen={isSaleModalOpen}
          onClose={() => setIsSaleModalOpen(false)}
          saleInfo={saleInfo}
          products={products}
          productSearchTerm={productSearchTerm}
          setProductSearchTerm={setProductSearchTerm}
          handleProductChange={handleProductChange}
          handleSaleSubmit={handleSaleSubmit}
        />

        <AnimatePresence>
          {isDeleteModalOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4 z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-gray-800 p-6 md:p-8 rounded-lg shadow-xl w-full max-w-md mx-auto"
              >
                <h3 className="text-2xl font-bold mb-6 text-blue-600 dark:text-blue-400">
                  Müşteriyi Sil
                </h3>
                <p className="text-gray-700 dark:text-gray-300 mb-4">
                  "{customerToDelete?.name}" isimli müşteriyi silmek
                  istediğinizden emin misiniz? Bu işlem geri alınamaz.
                </p>
                <p className="text-gray-700 dark:text-gray-300 mb-6">
                  Müşterinin toplam borcu:{" "}
                  <span className="font-bold text-red-500">
                    {customerToDelete?.totalDebt.toFixed(2)} TL
                  </span>
                </p>
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setIsDeleteModalOpen(false)}
                    className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition duration-150 ease-in-out"
                  >
                    İptal
                  </button>
                  <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-red-500 dark:bg-red-600 text-white rounded-md hover:bg-red-600 dark:hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 dark:focus:ring-red-500 transition duration-150 ease-in-out"
                  >
                    Sil
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        <ErrorMessage
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
        <ErrorMessage
          message={successMessage}
          type="success"
          onClose={() => setSuccessMessage("")}
        />
        
      </div>
    </div>
  );
};

export default Customers;
